





























import { Vue, Component, Prop, ModelSync } from "vue-property-decorator";

@Component
export default class CalendarRangeInput extends Vue {
    @ModelSync("datesValue", "change", { type: Array })
    readonly dates?: string[];

    @Prop({ type: Boolean, default: true, required: false })
    readonly disabled!: boolean;

    @Prop({ type: Array, required: false })
    rules!: string[];

    /**
     * Computed
     */
    get dateRangeText() {
        if (this.dates) {
            if (
                new Date(this.dates[0]).getTime() >
                new Date(this.dates[1]).getTime()
            ) {
                return `${this.dates[1]}  ~  ${this.dates[0]}`;
            } else if (
                new Date(this.dates[0]).getTime() <
                new Date(this.dates[1]).getTime()
            ) {
                return `${this.dates[0]}  ~  ${this.dates[1]}`;
            }
        }
        return "";
    }
}
